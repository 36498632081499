<template>
    <div>
        <v-dialog
            v-model="dialogClients"
            scrollable
            persistent
            max-width="300px"
        >
            <v-card>
                <v-card-title>Seleccionar cliente</v-card-title>
                <v-divider></v-divider>
                <v-alert
                        border="top"
                        color="orange"
                        type="warning"
                        class="mt-3"
                        v-if="errorSelected"
                    >Debe seleccionar un cliente</v-alert>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                    <v-radio-group
                        v-model="ClientSelected"
                        column
                    >
                        <v-radio
                            v-for="(value, key) in Clients" :key="key"
                            :label="value.name"
                            :value="value"
                        ></v-radio>
                    </v-radio-group>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="clienteSelected"
                >
                    Aceptar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        dialogClients: {
            type: Boolean,
            defaul: false
        }
    },
    data() {
        return {
            Clients: [],
            ClientSelected: null,
            errorSelected: false
        }
    },
    methods: {
        getClients(){
            this.$http.get('townhalls/getTownhalls/'+ this.getUser().user_id)
            // this.$http.get('townhalls')
            //this.$http.get('townhalls',{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    
                    response.body.forEach(element => {
                        this.Clients.push({
                            id: element.id,
                            name: element.name_townhall
                        });
                    });

                }).catch(error => {
                    console.log('Hubo un error al consultar las clientes, detalle del error: ', error)
                });
        },
        clienteSelected(){
            if (this.ClientSelected != null) {
                this.$emit('Client', this.ClientSelected);
            }else{
                this.errorSelected = true;
            }
        }
    },
    created() {
        this.getClients();
    },
}
</script>