import Vue from 'vue'
import Vuex from 'vuex'

import session from './modules/session'
import client from './modules/client'
import  createPersistedState  from  'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session: session,
    client: client,
  },
  plugins: [createPersistedState()],
})
