<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import Navigation from './components/shared/Navigation.vue'
  export default {
  components: { Navigation },
    data: () => ({
      Nav: 'Aplication'
    }),
  }
</script>
<style lang="scss">
  @import './assets/stylesheets/application.scss';
</style>