import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const router = new VueRouter({
  
  
  routes : [
    {
      path: '/',
      name: 'Login',
      component: () => import('@/components/authentication/Login.vue'),
    },
    {
      path: '/Dashboard',
      name: 'Dashboard',
      component: () => import('@/components/pages/Dashboard.vue'),
      children: [
        {
          path: 'Users',
          name: 'Users',
          component: () => import('@/components/pages/users/Users.vue'),
          meta: {title: 'CMS Observatorio | Usuarios'}
        },
        {
          path: 'Clients',
          name: 'Clients',
          component: () => import('@/components/pages/clients/Clients.vue'),
          meta: {title: 'CMS Observatorio | Clientes'}
        },
        {
          path: 'CreateClient',
          name: 'CreateClient',
          component: () => import('@/components/pages/clients/CreateClient.vue'),
          meta: {title: 'CMS Observatorio | Crear cliente'}
        },
        {
          path: 'Dimensions',
          name: 'Dimensions',
          component: () => import('@/components/pages/dimensions/Dimensions.vue'),
          meta: {title: 'CMS Observatorio | Dimensiones'}
        },
        {
          path: 'ViewDimension/:id',
          name: 'ViewDimension',
          component: () => import('@/components/pages/dimensions/DimensionView.vue'),
          meta: {title: 'CMS Observatorio | Dimensiones'}
        },
        {
          path: 'DataTemplate',
          name: 'DataTemplate',
          component: () => import('@/components/pages/graphs/DataTemplate.vue'),
          meta: {title: 'CMS Observatorio | Plantilla de datos'}
        },
        {
          path: 'DataKPI',
          name: 'DataKPI',
          component: () => import('@/components/pages/graphs/DataKPI.vue'),
          meta: {title: 'CMS Observatorio | Plantilla de datos KPI'}
        },
        {
          path: 'MapTemplate',
          name: 'MapTemplate',
          component: () => import('@/components/pages/maps/MapTemplate.vue'),
          meta: {title: 'CMS Observatorio | Cargue de mapas'}
        },
        {
          path: 'ImageTemplate',
          name: 'ImageTemplate',
          component: () => import('@/components/pages/imageload/ImageTemplate.vue'),
          meta: {title: 'CMS Observatorio | Cargue de Imagenes'}
        },
        {
          path: 'PdfTemplate',
          name: 'PdfTemplate',
          component: () => import('@/components/pages/pdfload/PdfTemplate.vue'),
          meta: {title: 'CMS Observatorio | Cargue de Pdfs'}
        },
        {
          path: 'VideoTemplate',
          name: 'VideoTemplate',
          component: () => import('@/components/pages/videoload/VideoTemplate.vue'),
          meta: {title: 'CMS Observatorio | Cargue de Videos'}
        },
        {
          path: 'ShowMaps/:id',
          name: 'ShowMaps',
          component: () => import('@/components/pages/maps/ShowMaps.vue'),
          meta: {title: 'CMS Observatorio | Ver mapa'}
        },
        {
          path: 'Townhall',
          name: 'Townhall',
          component: () => import('@/components/pages/clients/Townhall.vue'),
          meta: {title: 'CMS Observatorio | Cliente'}
        },
        {
          path: 'Townhall/:id',
          name: 'TownhallId',
          component: () => import('@/components/pages/clients/Townhall.vue'),
          meta: {title: 'CMS Observatorio | Cliente'}
        },
        {
          path: 'Segments',
          name: 'Segments',
          component: () => import('@/components/pages/segments/Segments.vue'),
          meta: {title: 'CMS Observatorio | Segmentos'}
        },
        {
          path: 'Segment/:id',
          name: 'Segment',
          component: () => import('@/components/pages/segments/SegmentView.vue'),
          meta: {title: 'CMS Observatorio | Segmentos'}
          
        },
        {
          path: 'NewSegment',
          name: 'NewSegment',
          component: () => import('@/components/pages/segments/NewSegment.vue'),
          meta: {title: 'CMS Observatorio | Segmentos'}
          
        },
        {
          path: 'Categories',
          name: 'Categories',
          component: () => import('@/components/pages/categories/Categories.vue'),
          meta: {title: 'CMS Observatorio | Categorias'}
        },
        {
          path: 'Category/:id',
          name: 'Category',
          component: () => import('@/components/pages/categories/CategoryView.vue'),
          meta: {title: 'CMS Observatorio | Categorias'}
          
        },
        {
          path: 'News',
          name: 'News',
          component: () => import('@/components/pages/news/News.vue'),
          meta: {title: 'CMS Observatorio | Noticias'}
        },
        {
          path: 'NewArticle/:id',
          name: 'NewArticle',
          component: () => import('@/components/pages/news/CreateNew.vue'),
          meta: {title: 'CMS Observatorio | Nueva Noticia'},
        },
        {
          path: 'Products/:id',
          name: 'Products',
          component: () => import('@/components/pages/products/Products.vue'),
          meta: {title: 'CMS Observatorio | Productos'},
        },
        {
          path: 'NewProduct/:id',
          name: 'NewProduct',
          component: () => import('@/components/pages/products/NewProduct.vue'),
          meta: {title: 'CMS Observatorio | Nuevo producto'},
        },
        {
          path: 'EditProduct/:id',
          name: 'EditProduct',
          component: () => import('@/components/pages/products/EditProduct.vue'),
          meta: {title: 'CMS Observatorio | Nuevo producto'},
        },
        {
          path: 'ViewTables/:id',
          name: 'ViewTables',
          component: () => import('@/components/pages/products/tables/ViewTables.vue'),
          meta: {title: 'CMS Observatorio | Graficas de producto'},
        },
        {
          path: 'NewCategory',
          name: 'NewCategory',
          component: () => import('@/components/pages/categories/NewCategory.vue'),
          meta: {title: 'CMS Observatorio | Categorias'}
          
        },  
        {
          path: 'DataMap',
          name: 'DataMap',
          component: () => import('@/components/pages/maps/DataMap.vue'),
          meta: {title: 'CMS Observatorio | Mapas'}
        },
        {
          path: 'DataTable',
          name: 'DataTable',
          component: () => import('@/components/pages/graphs/DataTable.vue'),
          meta: {title: 'CMS Observatorio | Tablas'}
        },
        {
          path: 'Importer',
          name: 'Importer',
          component: () => import('@/components/pages/importer/Importer.vue'),
          meta: {title: 'CMS Observatorio | Importador'}
        },
        {
          path: 'About',
          name: 'About',
          component: () => import('@/components/pages/about/About.vue')
        },
        {
          path: 'Program',
          name: 'Program',
          component: () => import('@/components/pages/program/Program.vue')
        },
        {
          path: 'FAQ',
          name: 'FAQ',
          component: () => import('@/components/pages/about/FAQ.vue')
        },
      ]
  
    }
  ]

})  

export default router
