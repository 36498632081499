<template>
  <div>
    <SelectClient :dialogClients="getClients" @Client="clientSelected" v-if="getClients"/>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{getClientSelect().name || 'Cliente no seleccionado'}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="getClients = !getClients"
          >
            <v-icon>mdi-home-city-outline</v-icon>
          </v-btn>
        </template>
        <span>Seleccionar cliente</span>
      </v-tooltip>
      <v-btn icon @click="$router.push({ name: 'Dashboard' });">
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-app-bar>
      <v-navigation-drawer 
        app
        dark
        v-model="drawer"
      >
      <v-list dense>
        <v-list-item>
          <v-avatar
            color="primary"
            size="56"
          ><span class="white--text headline">{{getInitialsName}}</span></v-avatar>
        </v-list-item>
      <v-list-group
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{getUser().first_name || 'Invitado'}} {{getUser().last_name || 'Fusepong'}}
            </v-list-item-title>
            <v-list-item-subtitle>{{getUser().email || 'Fusepong'}}</v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <v-list-item
          link
        >
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
        >
        
          <v-list-item-content>
            <v-list-item-title>Modificar contraseña</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-account-key</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
      <v-divider></v-divider>
      <v-list 
        dense
      >
      <v-list-item-group
        v-model="selectedItem"
        color="light-blue"
      >
        <v-subheader inset>Administración</v-subheader>
        <v-list-item @click="getRoute('Users')">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getRoute('Clients')">
          <v-list-item-icon>
            <v-icon>mdi-home-city-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader v-if="showMenu==true" inset>Observatorio</v-subheader>
        <v-list-item v-if="showMenu==true" @click="getRoute('Townhall')">
          <v-list-item-icon>
            <v-icon>mdi-home-city-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Información</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('Dimensions')">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dimensiones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('Segments')">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Segmentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('Categories')">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Categorias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('News')">
          <v-list-item-icon>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Noticias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('DataTemplate')">
          <v-list-item-icon>
            <v-icon>mdi-ballot-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Datos para Graficos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('DataKPI')">
          <v-list-item-icon>
            <v-icon>mdi-ballot-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Datos para Graficos KPI</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('MapTemplate')">
          <v-list-item-icon>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Graficos de mapas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('ImageTemplate')">
          <v-list-item-icon>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cargue de Imagenes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('PdfTemplate')">
          <v-list-item-icon>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cargue de Pdfs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('VideoTemplate')">
          <v-list-item-icon>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cargue de Videos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('Program')">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Programa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item v-if="showMenu==true" @click="getRoute('Importer')">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Importador</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('About')">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Quiénes somos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showMenu==true" @click="getRoute('FAQ')">
          <v-list-item-icon>
            <v-icon>mdi-frequently-asked-questions</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Preguntas frecuentes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        

        
       
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    data: () => ({
      selectedItem: 0,
      drawer: true,
      getClients: false,
      showMenu: false
    }),
    methods: {
        getRoute(item, params = {}){
          if (this.$router.currentRoute.name != item) {
            this.$router.push({ name: item, params: params });
          }
        },
        logout(){
          this.updateCrypto('');
          this.$router.push({ name: 'Login' });
        },
        clientSelected(data){
          this.updateClientSelect(data);
          this.getClients = false;
          this.showMenu = true;
          this.$router.push({ name: 'Dashboard' });
        },
    },
    computed: {
      getInitialsName(){
        const name = (this.getUser().first_name).split('')[0];
        const last_name = (this.getUser().last_name).split('')[0];
        
        return name+''+last_name
      }
    },
    beforeMount(){
      if (this.getClientSelect().id != '' || null || undefined){
        this.showMenu = true;
      }
    }
}
</script>