import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import './vue/resource'
import router from './vue/router'
import store from './vue/store/index'
import VuePersist from 'vue-persist'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import Vuelidate from 'vuelidate'
import './vue/mixins'
import 'vue-tableau'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import VueSvgGauge from 'vue-svg-gauge'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(VueSvgGauge)
Vue.use(VueQuillEditor)


Vue.use(VuePersist)
Vue.use(Vuelidate);

Vue.use(Chartkick.use(Chart))

Vue.config.productionTip = false

Vue.component('Navigation', require('./components/shared/Navigation.vue').default)
Vue.component('SelectClient', require('./components/shared/SelectClient.vue').default)
Vue.component('Loading', require('./components/pages/videoload/ModalLoading.vue').default)


new Vue({
  router,
  store: store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
