const state = {
    clientSelect: {
        id:0,
        name:'Cliente No Seleccionado'
    },
}
  
const getters = {
    getClientSelect(state){
        return state.clientSelect;
    },
}
  
const mutations = {
    setClientSelect(state, payload){
        state.clientSelect = payload;
    },
}
  
const actions = {
    updateClientSelect ({commit}, payload) {
        commit('setClientSelect', payload)
    },
}
  
  export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
  }