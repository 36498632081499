import Vue from 'vue'
import { mapActions, mapGetters  } from 'vuex'

Vue.mixin({
  
    methods: {
        ...mapActions([
            "updateCrypto",
            "updateUser",
            "updateClientSelect"
        ]),
        ...mapGetters([
            "getCrypto",
            "getUser",
            "getClientSelect"
        ]),


    }
});