const state = {
    crypto: null,
    user: null
}
  
const getters = {
    getCrypto(state){
        return state.crypto;
    },
    getUser(state){
        return state.user;
    }
}
  
const mutations = {
    setCrypto(state, payload){
        state.crypto = payload;
    },
    setUser(state, payload){
        state.user = payload;
    }
}
  
const actions = {
    updateCrypto ({commit}, payload) {
        commit('setCrypto', payload)
    },
    updateUser ({commit}, payload) {
        commit('setUser', payload)
    },
}
  
  export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
  }