<template>
    <div class="container__loading">
        <div class="container__loading--message">
            <p>{{message}}</p>
        </div>
    </div>
</template>
<script>


export default {
   data(){
       return{
           message:''
       }
   },
   props:{
       onload:{
           type:Boolean
       }
   },
   methods:{
       loadingText(){
           console.log('probando loading',this.onload)
           setInterval(() => this.message = 'Cargando', 1500);
            setInterval(() => this.message = 'Cargando.', 2000);
            setInterval(() => this.message = 'Cargando..', 2500);
            setInterval(() => this.message = 'Cargando...', 3000);
        }
   },
   beforeMount(){
    this.loadingText()
   }
}
</script>
<style scoped>
.container__loading{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.6);
    right: 0;
    top: 0;
}
.container__loading--message{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.container__loading--message p{
    font-size: 35px;
}
</style>
